<template lang="pug">
  .detail-w(v-if='informationDetail')
    GeminiScrollbar(class="my-scroll-w" style="height:100%")
      .content-d
        .title-d {{informationDetail.title}}
        .date-d {{informationDetail.createTime}}
        .desc-d(v-html="informationDetail.content")
        .files(v-for="(f,index) in informationDetail.listFile") <i class="iconfont icon-a-fujian"></i> 附件：<a :href="f.fileUrl">附件{{index+1}}</a>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      informationDetail: (state) => state.insuranceHome.informationDetail, //详情信息
    }),
  },
};
</script>

<style lang="less" scoped>
.detail-w {
  width: 68%;
  margin: 0 auto;
  background: rgba(28, 42, 117, 0.4);
  color: #fff;
  height: 82vh;
  .content-d {
    .df;
    .dfc;
    padding: 20px 80px;
    & > div {
      width: 100%;
    }
  }
  .title-d {
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    padding: 20px 0;
  }
  .date-d {
    font-size: 14px;
    color: #5a86d3;
    line-height: 21px;
    text-align: center;
    padding-bottom: 20px;
  }
  .desc-d {
    flex: 1;
    font-size: 16px;
    color: #5a86d3;
    line-height: 24px;
  }
  .files {
    text-align: left;
    color: #5a86d3;
    a {
      display: inline-block;
      color: #5a86d3;
      text-decoration: underline;
      line-height: 30px;
    }
    a:hover {
      color: #bed6ff;
    }
  }
}
</style>
